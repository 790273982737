<template>
  <div v-if="title">
    <div v-if="vertical" :class="customClass" class="flex flex-col h-full">
      <div class="flex-grow rounded-lg bg-lightgray relative w-4 mx-auto">
        <div :style="{
              'height': get_bar_width() + '%',
              'background-color': get_header_color()
      }"
             class="w-4 rounded-lg absolute bottom-0"></div>
      </div>
      <div :class="customClass" class="mt-3 text-center">{{ title }}</div>
    </div>
    <div v-else :class="customClass" class="flex flex-col w-full items-center">
      <div :class="customClass"
           class="text-center"
           v-html="title"
           style="hyphens: auto">
      </div>
      <div class="w-full rounded-lg bg-lightgray relative h-4">
        <div :style="{
              'width': get_bar_width() + '%',
              'background-color': get_header_color()
         }"
             class="h-4 rounded-lg absolute left-0"></div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-lightgray {
  background-color: rgb(248, 248, 248);
}
</style>

<script>
import {calc_score} from "@/helpers/character";

export default {
  name: "TraitBar",
  props: {
    vertical: {
      type: Boolean,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    customClass: {
      type: String
    },
    trait: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      title: null
    }
  },
  mounted() {
    let title;
    if (this.$props.trait) {
      title = this.ucfirst(this.$store.getters['character/categories'][this.$props.trait]);
      if (this.$store.getters['account/selected_locale'] === 'de') {
        title = this.$store.getters['character/categories_de'][title];
      }
    } else {
      title = this.$props.header;
      if (this.$store.getters['account/selected_locale'] === 'de') {
        title = this.$store.getters['character/big_five_de'][title];
      }
    }
    this.title = title;
  },
  methods: {
    ucfirst(str) {
      return str[0].toUpperCase() + str.substring(1);
    },
    percentage(trait) {
      const value = this.$store.getters['character/traits'][trait] - 1;
      const max_value = this.$store.getters['character/question_limit'] / 30 * 5 - 1;
      return value / max_value * 100;
    },
    get_bar_width() {
      if (this.$props.trait) {
        return this.get_trait_width(this.$props.trait);
      }
      return this.get_header_width(this.$props.header);
    },
    get_trait_width(trait) {
      const percentage = this.percentage(trait);
      const reference_percentage = this.$store.getters['character/reference_traits'][trait];
      return calc_score(percentage, reference_percentage);
    },
    get_header_width(header) {
      let sum = 0;
      let traits = this.$store.getters['character/big_five'][header];
      for (let i = 0; i < traits.length; ++i) {
        const trait = traits[i];
        sum += this.get_trait_width(trait);
      }
      return sum / 6;
    },
    get_header_color() {
      const colors = {
        yellow: "rgb(236, 203, 63)",
        orange: "rgb(246, 135, 41)",
        pink: "rgb(203, 24, 150)",
        blue: "rgb(59, 160, 223)",
        red: "rgb(233, 29, 43)"
      };
      const bullshit = {
        'Extraversion': colors.orange,
        'Openness': colors.yellow,
        'Agreeableness': colors.pink,
        'Conscientiousness': colors.blue,
        'Neuroticism': colors.red
      };
      return bullshit[this.$props.header];
    },
    get_style(trait, header, fixed_percentage) {
      const color = this.get_color(header);
      let percentage = fixed_percentage ? fixed_percentage : this.relative_percentage(trait);
      return `width: ${percentage}%; opacity: ${percentage / 100}; background-color: ${color}`;
    }
  }
}
</script>