<template>
  <div id="post-premium-modal" class="fixed bg-white px-10 py-5 shadow-lg rounded-xl hidden"
       style="top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 101; width: 95%; max-width: 500px;
       max-height: 500px">
    <div v-if="success === true">
      <div class="flex justify-between items-center mb-4">
        <img class="h-12" src="../assets/wiggle3.png"/>
        <h1 v-if="success === true">{{ $t('postPremiumHandler.success.title') }}</h1>
        <img class="h-12" src="../assets/wiggle2.png"/>
      </div>

      <hr/>

      <div class="mt-2">
        {{ $t('postPremiumHandler.success.subtitle') }}
      </div>

      <div v-if="waiting === true" class="text-center mt-4">
        {{ $t('postPremiumHandler.success.waiting') }}
        <i class="fas fa-spinner fa-spin ml-2 text-xl"></i>
      </div>
      <div v-else-if="waiting === false" class="text-center mt-4">
        <button class="p-3 py-4 bg-blue text-white rounded-xl mt-3 mx-auto button-lg" @click="logout">
          {{ $t('menu.logOut') }}
        </button>
      </div>
    </div>

    <div v-else>
      <div class="flex justify-between items-center mb-4">
        <img class="h-12" src="../assets/wiggle3.png"/>
        <h1>{{ $t('postPremiumHandler.cancelled.title') }}</h1>
        <img class="h-12" src="../assets/wiggle2.png"/>
        <div class="cursor-pointer" @click="close()">
          <i class="fas fa-times text-3xl"></i>
        </div>
      </div>
      <hr/>
      <div class="mt-2">
        {{ $t('postPremiumHandler.cancelled.subtitle') }}
      </div>

      <div class="flex justify-center">
        <button class="p-3 py-4 bg-blue text-white rounded-xl mt-3 mx-auto button-lg" @click="close">
          {{ $t('postPremiumHandler.cancelled.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import websocketConsumer from "@/websocketConsumer";
import {logout} from "@/helpers/account";
import axios from '../axios';

export default {
  name: "PostPremiumHandler",
  data() {
    return {
      success: null,
      waiting: null
    }
  },
  methods: {
    close() {
      this.$store.commit('common/setShowPostPremiumModal', {value: false});
    },
    onPaymentSuccess() {
      this.waiting = false;
    },
    logout() {
      this.close();
      logout();
    }
  },
  mounted() {
    websocketConsumer.register('payment:success', this.onPaymentSuccess);
    const modal = document.getElementById('post-premium-modal');
    document.body.appendChild(modal);
    document.getElementById('mycharacter').classList.toggle('blurry');
    document.getElementById('post-premium-modal').classList.toggle('hidden');
    document.getElementById('blackout').classList.toggle('hidden');
    let queryString = new URLSearchParams(location.search);
    if (queryString.has('paypal_return')) {
      this.success = true;
      this.waiting = true;
    } else if (queryString.has('paypal_cancel')) {
      this.success = false;
    }
    axios.get('/account/has_premium/').then(response => {
      if (response.data.has_premium === true) {
        this.waiting = false;
      }
    });
  },
  unmounted() {
    document.getElementById('mycharacter').classList.toggle('blurry');
    document.getElementById('blackout').classList.toggle('hidden');
  }
}
</script>