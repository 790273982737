<template>
  <div id="fixed-ratio-container-parent" class="relative p-4 md:p-8 flex flex-grow w-full justify-center">
    <div id="fixed-ratio-container" :class="containerClass" class="w-full">
      <slot></slot>
    </div>
  </div>
  <!--<div class="absolute top-0 left-0 bg-white z-50">
    {{ width }} x {{ height }}, max: {{ maximumWidth }} x {{ maximumHeight }}
  </div>-->
</template>

<script>
export default {
  name: "FixedRatioContainer",
  props: {
    containerClass: {
      type: String,
      default: ''
    },
    ratio: {
      type: Number,
      default: 8 / 16
    },
    maxWidth: {
      type: Number,
    },
    maxHeight: {
      type: Number
    }
  },
  data() {
    return {
      width: null,
      height: null,
      maximumWidth: null,
      maximumHeight: null
    }
  },
  methods: {
    resizeContainer() {
      const container = document.getElementById('fixed-ratio-container');
      const menuRect = document.getElementById('menu').getBoundingClientRect();
      const parentRect = document.getElementById('fixed-ratio-container-parent').getBoundingClientRect();

      const padding = 60;
      const maximumHeight = this.$props.maxHeight ?? window.innerHeight - menuRect.height - padding;
      const maximumWidth = this.$props.maxWidth ?? parentRect.width - padding;
      this.maximumWidth = maximumWidth;
      this.maximumHeight = maximumHeight;

      let newHeight = maximumHeight;
      let newWidth = newHeight / this.$props.ratio;

      if (newHeight > maximumHeight) {
        newHeight = maximumHeight;
        newWidth = newHeight / this.$props.ratio;
      }

      if (newWidth > maximumWidth) {
        newWidth = maximumWidth;
        newHeight = Math.min(maximumHeight, this.$props.ratio * newWidth);
      }

      if(newHeight < 500) {
        newHeight = 500;
        if(window.innerWidth < 768) {
          newHeight = window.innerHeight - menuRect.height - 50;
        }
        newWidth = Math.min(maximumWidth, newHeight / this.$props.ratio);
      }

      container.style.width = newWidth + 'px';
      container.style.height = newHeight + 'px';

      this.width = newWidth;
      this.height = newHeight;
    }
  },
  mounted() {
    this.resizeContainer();
    window.addEventListener('resize', this.resizeContainer);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeContainer);
  }
}
</script>