<template>

  <div v-if="useDeviceLocation" class="flex flex-col overflow-scroll no-scrollbar">
    <div class="font-bold text-2xl text-center mb-5">{{ $t('determineLocation.title') }}</div>
    <img class="mw-300" src="../assets/location.png"/>
    <div class="mt-5 text-center lg:w-1/2 mx-auto">{{ $t('determineLocation.subtitle') }}</div>
    <input v-model="coordinates_form.latitude" :placeholder="$t('determineLocation.placeholder.latitude')"
           class="p-3 bg-gray rounded-xl mx-auto mt-5 mb-3"
           disabled type="text"/>
    <input v-model="coordinates_form.longitude" :placeholder="$t('determineLocation.placeholder.longitude')"
           class="p-3 bg-gray rounded-xl mx-auto"
           disabled type="text"/>

    <button class="button-lg p-3 py-5 bg-blue text-white rounded-xl mt-5 mx-auto"
            :disabled="$store.getters['common/waitingForResponse']"
            @click="determine_location_from_coordinates()">
      <span v-if="!$store.getters['common/waitingForResponse']">{{ $t('determineLocation.useDeviceLocation') }}</span>
      <span v-else><i class="fa fa-spinner fa-spin text-white text-xl"></i></span>
    </button>

    <button class="button-lg bg-white text-darkblue text-lg mx-auto mt-5" @click="toggleUseDeviceLocation">{{
        $t('determineLocation.enterManually')
      }}
    </button>
  </div>
  <div v-else class="flex flex-col overflow-scroll no-scrollbar">
    <input v-model="address_form.city" :placeholder="$t('determineLocation.placeholder.city')"
           name="city"
           class="p-3 rounded-xl mx-auto mt-5 mb-3"
           minlength="3"
           type="text"/>
    <input v-model="address_form.state" :placeholder="$t('determineLocation.placeholder.state')"
           name="state"
           minlength="3"
           class="p-3 rounded-xl mx-auto"
           type="text"/>

    <button class="button-lg p-3 py-5 bg-blue text-white rounded-xl mt-5 mx-auto"
            :disabled="$store.getters['common/waitingForResponse'] || !address_form.city || !address_form.state"
            @click="determine_location_from_address()">
      <span v-if="!$store.getters['common/waitingForResponse']">{{ $t('determineLocation.send') }}</span>
      <span v-else><i class="fa fa-spinner fa-spin text-white text-xl"></i></span>
    </button>

    <button class="button-lg bg-white text-darkblue text-lg mx-auto mt-5" @click="toggleUseDeviceLocation">{{
        $t('determineLocation.useDeviceLocation')
      }}
    </button>
  </div>
</template>

<style>
input {
  border: 2px solid rgb(226 228 230);
  width: 250px;
}

.button-lg {
  width: 250px;
}

.text-darkblue {
  color: rgb(0 22 58);
}
</style>

<script>
import axios from '../axios';

export default {
  emits: ['locationdetermined'],
  data() {
    return {
      address_form: {
        city: null,
        state: null
      },
      coordinates_form: {
        latitude: null,
        longitude: null
      },
      response_location: null,
      useDeviceLocation: true
    }
  },
  methods: {
    async set_location() {
      let response = await axios.post('/location/set_user_location/', {
        city: this.response_location.city,
        county: this.response_location.county,
        state: this.response_location.state,
        country: this.response_location.country,
        language: this.$store.getters['account/selected_language']
      });

      this.$store.commit('location/set', {location: this.response_location});
      this.$store.commit('users/set_distances', {
        latitude: this.$store.getters['location/latitude'],
        longitude: this.$store.getters['location/longitude'],
        max_distance: this.$store.getters['compare_character/distance_upper_bound']
      });
      response = await axios.get('/account/get_all/');
      await this.$store.commit('users/set', {
        users: response.data
      });
      this.$emit('locationdetermined');
    },
    async determine_location_from_address() {
        await axios.post('/location/retrieve/', this.address_form).then(response => {
          this.response_location = response.data.location;
          this.$store.commit('account/set_location_string', {location_string: response.data.location_string});
        });

        await this.set_location();
    },
    determine_location_from_coordinates() {
      let self = this;
      this.coordinates_form.latitude = this.$i18n.t('determineLocation.triangulating');
      this.coordinates_form.longitude = this.$i18n.t('determineLocation.triangulating');
      this.$store.commit('common/setWaitingForResponse', { status: true });

      navigator.geolocation.getCurrentPosition(
          async function (position) {
              self.coordinates_form.latitude = position.coords.latitude;
              self.coordinates_form.longitude = position.coords.longitude;
              await axios.post('/location/retrieve/', {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              }).then(response => {
                self.response_location = response.data.location;
                this.$store.commit('account/set_location_string', {location_string: response.data.location_string});
              });

              await this.set_location();
          }.bind(this),
          function (error) {
            alert(`Please type in your location manually (error: ${error.message}`);
            this.$store.commit('common/setWaitingForResponse', { status: false });
          },
          {enableHighAccuracy: true}
      );
    },
    toggleUseDeviceLocation() {
      this.useDeviceLocation = !this.useDeviceLocation;
    }
  }
}
</script>