<template>
  <FixedRatioContainer v-if="$store.getters['location/determined']"
                       :container-class="'flex flex-col md:grid md:grid-cols-12 md:grid-rows-5 gap-10'">
    <div class="grid-box md:col-span-8 md:row-span-2 h-40 md:h-auto p-5 gap-1 grid grid-cols-2 md:grid-cols-3">
      <div class="flex flex-col items-center justify-start col-span-1 md:col-span-2">
        <div class="font-bold text-xl text-center">{{ $t('dashboard.welcome', {username:
              $store.getters['account/username']})
          }}</div>
        <p class="hidden lg:block text-left mt-4">{{ $t('dashboard.newTest') }}</p>
        <button class="px-6 py-4 bg-blue text-white rounded-xl mt-4"
                @click="$router.push('/choose-test')">{{
            $t('dashboard.startTest')
          }}
        </button>
      </div>
      <div class="flex items-center justify-center">
        <img class="max-h-32" src="../assets/dude.png"/>
      </div>
    </div>
    <div class="grid-box md:col-span-4 md:row-span-2 p-5 h-60 md:h-auto">
      <div class="flex justify-between mb-2 h-1/5">
        <h2 class="text-left">{{ $t('dashboard.suggestedActivities') }}</h2>
        <router-link class="no-underline text-xl font-light" to="/activity-suggestions">{{
            $t('dashboard.viewAll')
          }}
        </router-link>
      </div>
      <div class="flex h-4/5 pb-2">
        <div v-for="activity in filtered_activities"
             :key="activity"
             class="w-1/3 rounded-2xl flex flex-col border-lightgray mx-2">
          <img :src="$store.getters['common/baseURL'] + activity.image"
               class="rounded-2xl object-cover"
               style="height: 70%"/>
          <h2 class="pt-0.5 flex items-center justify-center" style="height: 30%">{{ activity.score }}%</h2>
        </div>
      </div>
    </div>
    <div class="grid-box md:col-span-8 md:row-span-3 mh-250 md:h-auto p-5">
      <div class="flex justify-between mb-7">
        <h2 class="text-left">{{ $t('dashboard.characterOverview') }}</h2>
        <router-link class="no-underline text-xl font-light" to="/character-overview">{{
            $t('dashboard.viewAll')
          }}
        </router-link>
      </div>
      <div class="flex grid grid-cols-3 b-600:grid-cols-4 b-1000:grid-cols-5 h-3/4">
        <TraitBar v-for="(trait_names, header) in spliced_big_five" :key="header"
                  :header="header" :vertical="true">
        </TraitBar>
      </div>
    </div>
    <div class="grid-box md:col-span-4 md:row-span-3 h-64 md:h-auto px-5 pt-5 pb-0 relative h-1/2 md:h-full flex flex-col">
      <div v-if="filtered_users.length > 0"
           class="absolute bottom-0 z-10 inset-x-0 h-1/6 rounded-xl"
           style="background: linear-gradient(0, white, transparent)"></div>
      <div class="flex justify-between mb-2">
        <h2 class="text-left">{{ $t('dashboard.characterComparison') }}</h2>
        <router-link class="no-underline text-xl font-light" to="/compare-characters">{{
            $t('dashboard.viewAll')
          }}
        </router-link>
      </div>
      <div class="overflow-scroll no-scrollbar flex-grow">
        <div v-if="filtered_users.length > 0" class="grid grid-rows-5">
          <div v-for="user in filtered_users" :key="user" class="grid-rows-1 grid grid-cols-12 my-2">
            <div class="col-span-3 flex justify-center items-center">
              <img v-if="user.profile_picture"
                   :src="$store.getters['common/baseURL'] + user.profile_picture"
                   class="object-cover"
                   style="height: 40px; width: 40px; border-radius: 50%"/>
              <div v-else class="bg-gray-300 flex items-center justify-center"
                   style="width: 40px; height: 40px; border-radius: 50%;">
                <i class="fas fa-user text-xl text-white"></i>
              </div>
            </div>
            <div class="col-span-7 flex items-center">{{ user.username }}</div>
            <div class="col-span-2 font-bold flex items-center">{{
                $store.getters['account/has_premium']? `${user.character_congruence}%` :
                    $store.getters['character/format_distance'](user.distance) }}</div>
          </div>
        </div>
        <div v-else
             class="flex flex-col flex-grow justify-center items-center flex-grow">
          <div class="text-xl">{{ $t('dashboard.nobodyHere') }}</div>
          <img src="../assets/sloth_with_phone.jpg" class="w-72 md:w-32 xl:w-56 rounded-xl my-2"/>
          {{ $t('dashboard.tellYourFriends')}}
        </div>
      </div>
    </div>
  </FixedRatioContainer>
  <FixedRatioContainer v-else :container-class="'bg-lightgray mx-auto'">
    <div class="grid-box p-4 md:p-8 w-full h-full flex flex-col">
      <DetermineLocation v-if="!$store.getters['location/determined']"
                         @locationdetermined="on_location_determined"></DetermineLocation>
    </div>
  </FixedRatioContainer>
  <PostPremiumHandler v-if="$store.getters['common/showPostPremiumModal']"></PostPremiumHandler>
</template>

<style>
.border-lightgray {
  border: 1px solid rgb(240, 240, 240);
}

.mh-250 {
  min-height: 250px;
}
</style>

<script>
import axios from "../axios";
import FixedRatioContainer from "@/components/FixedRatioContainer";
import TraitBar from "@/components/TraitBar";
import PostPremiumHandler from "@/components/PostPremiumHandler";
import DetermineLocation from "@/components/DetermineLocation";

export default {
  components: {PostPremiumHandler, TraitBar, FixedRatioContainer, DetermineLocation},
  data() {
    return {
      filtered_users: [],
      spliced_big_five: {},
      filtered_activities: []
    }
  },
  methods: {
    ucfirst(str) {
      return str[0].toUpperCase() + str.substring(1);
    },
    create_spliced_big_five() {
      const big_five = JSON.parse(JSON.stringify(this.$store.getters['character/big_five']));
      if(window.innerWidth < 1000) {
        delete big_five['Neuroticism'];
      }
      if(window.innerWidth < 600) {
        delete big_five['Conscientiousness'];
      }

      this.spliced_big_five = big_five;
    },
    fill_activity_preview() {
      if(this.$store.getters['account/has_premium']) {
        this.filtered_activities = this.$store.getters['activity/activities'].splice(0, 3);
      }
      else {
        for(const activity of this.$store.getters['activity/activities']) {
          if(activity.trait_weights === null) {
            continue;
          }
          this.filtered_activities.push(activity);
          if(this.filtered_activities.length === 3) {
            this.filtered_activities.sort((a, b) => { return a.score - b.score });
            return;
          }
        }
      }
    },
    on_location_determined() {
      this.$store.commit('activity/sort', {
        key: (a, b) => {
          return a.name.localeCompare(b.name);
        }
      });

      this.$store.commit('users/set_distances', {
        latitude: this.$store.getters['location/latitude'],
        longitude: this.$store.getters['location/longitude'],
        max_distance: this.$store.getters['compare_character/distance_upper_bound']
      });

      this.$store.commit('users/sort', {key: (a, b) => b.distance - a.distance});
      const filtered_users = this.$store.getters['users/all'].slice(0, 100);
      if(this.$store.getters['account/has_premium']) {
        filtered_users.sort((a, b) => b.character_congruence - a.character_congruence);
      }
      else {
        filtered_users.sort((a, b) => a.distance - b.distance);
      }
      this.filtered_users = filtered_users.slice(0, 10);
    }
  },
  async mounted() {
    if (this.$store.getters["character/traits"] === null) {
      await axios.get("/character/", {
        params: {
          locale: this.$store.getters['account/selected_locale']
        }
      }).then((response) => {
        this.$store.commit("character/set", {
          character: response.data.character,
        });
        this.$store.commit("users/set", {
          users: response.data.users
        });
      });
    }

    if (!this.$store.getters['activity/scores_calculated']) {
      const args = {
        question_limit: this.$store.getters['character/questions'].length,
        traits: this.$store.getters['character/traits'],
        reference_traits: this.$store.getters['character/reference_traits']
      };
      const key = (a, b) => {
        return b.score - a.score;
      };
      this.$store.commit('activity/calculate_scores', args);
      this.$store.commit('activity/sort', {key});
    }

    if (!this.$store.getters['users/calculated_distances']) {
      this.$store.commit('users/set_distances', {
        latitude: this.$store.getters['location/latitude'],
        longitude: this.$store.getters['location/longitude'],
        max_distance: this.$store.getters['compare_character/distance_upper_bound']
      });
    }

    if(this.$store.getters['location/determined']) {
      this.on_location_determined();
    }

    this.create_spliced_big_five();
    window.addEventListener('resize', this.create_spliced_big_five);

    let queryString = new URLSearchParams(location.search);
    this.$store.commit('common/setShowPostPremiumModal', {
      value: queryString.has('paypal_cancel') || queryString.has('paypal_return')
    });

    this.fill_activity_preview();
  },
  unmounted() {
    window.removeEventListener('resize', this.create_spliced_big_five);
  }
};
</script>
